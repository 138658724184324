@use "src/styles/palette";
@use "src/styles/typography";
@use "src/styles/layout";

.timer {
  @extend .header_2;
  display: flex;
  font-weight: 800;

  .time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 3.125rem;
    padding: 0 0.625rem;
    border-right: solid 1px palette.grey("border");

    @media (min-width: layout.breakpoint(sm)) {
      padding: 0 1.375rem;
    }

    &:last-child {
      border-right: none;
    }

    span {
      font-size: 0.875rem;
      @extend .body;
      font-weight: 100;

      @media (min-width: layout.breakpoint(xs)) {
        font-size: 1rem;
      }
    }
  }
}

[data-theme="750"] {
  .timer {
    .time {
      border-right: solid 1px var(--black);
    }
  }
}
