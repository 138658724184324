@use "src/styles/palette";
@use "src/styles/typography";
@use "src/styles/layout";

.divider {
  @extend .divider;
}

.countdown {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: layout.breakpoint(md)) {
    flex-direction: row;
  }

  @media (min-width: layout.breakpoint(lg)) {
    gap: 7.625rem;
  }

  .content {
    max-width: 420px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      font-size: 2rem;
      margin-bottom: 1rem;

      @media (min-width: 420px) {
        font-size: 4.5rem;
      }
    }

    .pre_title {
      @extend .header_5;
      font-weight: 800;
    }

    .description {
      margin: 1rem 0 2rem;
      line-height: 150%;
    }

    .button {
      width: fit-content;
    }
  }

  .event {
    background-color: palette.grey("light");
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.3125rem;

    .container {
      position: relative;
      // TODO: this should not be necessary here, but before removing it, we should test it
      z-index: 1;
      padding: 1.25rem;
      gap: 1.25rem;
      display: flex;
      flex-direction: column;

      @media (min-width: layout.breakpoint(sm)) {
        padding: 2.5rem;
        gap: 2.5rem;
      }

      .image {
        width: 100%;
      }

      .title {
        @extend .header_3;
        text-transform: uppercase;

        span {
          margin-left: 0.5rem;
        }
      }

      .button {
        width: fit-content;
      }
    }
  }
}

.background {
  transform: rotate(180deg);
}

[data-theme="750"] {
  .pre_title {
    text-transform: uppercase;
  }
}
